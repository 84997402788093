import { Company } from '../../models/company'
import {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FetchCompanyActionTypes
} from './types'

const fetchCompanyRequest = (): FetchCompanyActionTypes => ({
  type: FETCH_COMPANY_REQUEST
})

const fetchCompanySuccess = (company: Company): FetchCompanyActionTypes => ({
  type: FETCH_COMPANY_SUCCESS,
  payload: { company }
})

const fetchCompanyFailure = (): FetchCompanyActionTypes => ({
  type: FETCH_COMPANY_FAILURE
})

export default {
  fetchCompanyRequest,
  fetchCompanySuccess,
  fetchCompanyFailure
}
