import { createSelector } from 'reselect'
import { Company } from '../../models/company'
import { RootState } from '../types'
import { State } from './types'

const memberSelector = (state: RootState): State => state.company

export const getCompany = createSelector(
    memberSelector,
    (state: State): Company => state.company
  )

export const selectors = {
  getCompany
}
