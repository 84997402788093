import { connect } from 'react-redux'
import { Company as Base, Props, Handlers } from './Company'
import { RootState } from '../../state/types'
import { getCompany } from '../../state/company/selectors'
import { getList as getHistory } from '../../state/history/selectors'
import { fetchCompany } from '../../state/company/operations'
import { fetchList as fetchHistory } from '../../state/history/operations'

const mapStateToProps = (state: RootState): Props => ({
  company: getCompany(state),
  history: getHistory(state)
})

const mapDispatchToProps: Handlers = {
  fetchCompany,
  fetchHistory
}

export const Company = connect(mapStateToProps, mapDispatchToProps)(Base)
