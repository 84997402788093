import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Company } from '../components/Company'

const IndexPage = () => (
  <Layout>
    <SEO
      title="遠隔医療で健幸へ導くリモハブの会社案内"
      description="わたしたちRemohab（リモハブ）は、オンライン心臓リハビリシステムで、医療機関とご自宅をクラウドでつなぐ、世界一の在宅疾病管理プラットフォームを創ります。最終的には患者教育、服薬管理、栄養指導、遠隔診療を通して、より多くの人を「健幸」へと導いていきます。"
      keywords={['心疾患','リハビリ','遠隔診療','会社案内','ビジョン','目的','リモハブ','remohab']}
    />
    <H1>心疾患　リハビリ　遠隔診療　会社案内　概要 | Remohab リモハブ</H1>
    <Company />
  </Layout>
)

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default IndexPage
