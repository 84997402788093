import actions from './actions'
import { getCompany } from '../../lib/httpService/api'

export const fetchCompany = (draftKey: string) => async (dispatch: any) => {
  dispatch(actions.fetchCompanyRequest)

  try {
    const company = await getCompany({ draftKey })
    dispatch(actions.fetchCompanySuccess(company))
  } catch (err) {
    console.log(err)
    dispatch(actions.fetchCompanyFailure)
  }
}