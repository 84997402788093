import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Reveal, Animation } from 'react-genie'
import { customMedia } from '../common/customMedia'
import { parse } from 'querystring'
import { Company as CompanyModel } from '../../models/company'
import { History } from '../../models/history'
import { Image } from '../common/Image'
import { useTranslation, Trans } from 'react-i18next'

export interface Props {
  company: CompanyModel
  history: History[]
}

export interface Handlers {
  fetchCompany: (draftKey: string) => void
  fetchHistory: (draftKey: string) => void
}

export const Company = (props: Props & Handlers) => {
  const { company, history } = props
  const { fetchCompany, fetchHistory } = props

  const [t, i18n] = useTranslation()

  useEffect(() => {
    const query = parse(window.location.search.slice(1))

    fetchCompany(query.draftKey as string)
    fetchHistory(query.draftKey as string)
  }, [])

  return (
    <>
      {company && (
        <Container>
          <Wrapper>
            <Reveal delay={500}>
              <CompanyIconWrapper>
                <Image path="company@2x.png" />
              </CompanyIconWrapper>
            </Reveal>

            <CompanyDetailWrapper>
              <Reveal delay={500}>
                <Title>{t('companyName')}</Title>
              </Reveal>
              <Reveal delay={500}>
                <Content>
                  {i18n.language === 'ja' ? company.name : company.enName}
                </Content>
              </Reveal>
            </CompanyDetailWrapper>

            <CompanyDetailWrapper>
              <Reveal delay={500}>
                <Title>{t('headOffice')}</Title>
              </Reveal>
              <Reveal delay={500}>
                <Content>
                  {i18n.language === 'ja' ? company.address : company.enAddress}
                </Content>
              </Reveal>
            </CompanyDetailWrapper>

            <CompanyDetailWrapper>
              <Reveal delay={500}>
                <Title>{t('establishment')}</Title>
              </Reveal>
              <Reveal delay={500}>
                <Content>
                  {i18n.language === 'ja'
                    ? company.established
                    : company.enEstablished}
                </Content>
              </Reveal>
            </CompanyDetailWrapper>

            <CompanyDetailWrapper>
              <Reveal delay={500}>
                <Title>{t('member')}</Title>
              </Reveal>
              {company.board.map((m, i) => (
                <CompanyDetailWrapper key={i}>
                  <Reveal delay={500}>
                    <Title>
                      {i18n.language === 'ja' ? m.title : m.enTitle}
                    </Title>
                  </Reveal>
                  <Reveal delay={500}>
                    <Content>
                      {i18n.language === 'ja' ? m.name : m.enName}
                    </Content>
                  </Reveal>
                </CompanyDetailWrapper>
              ))}
            </CompanyDetailWrapper>

            <CompanyDetailWrapper>
              <Reveal delay={500}>
                <Title>{t('principalShareholders')}</Title>
              </Reveal>
              {(i18n.language === 'ja'
                ? company.stockholder
                : company.enStockholder
              )
                .split('\n')
                .map((m, i) => (
                  <Reveal delay={500} key={i}>
                    <StockHolderContent>{m}</StockHolderContent>
                  </Reveal>
                ))}
            </CompanyDetailWrapper>

            <CompanyDetailWrapper>
              <Reveal delay={500}>
                <Title>{t('authorization')}</Title>
              </Reveal>
              <Reveal delay={500}>
                <Content>
                  {i18n.language === 'ja'
                    ? company.certificate
                    : company.enCertificate}
                </Content>
              </Reveal>
            </CompanyDetailWrapper>
          </Wrapper>
        </Container>
      )}

      {company && (
        <Container>
          <Wrapper>
            <Reveal delay={500} animation={Animation.SlideInLeft}>
              <DestinationIconWrapper>
                <Image path="destination@2x.png" />
              </DestinationIconWrapper>
            </Reveal>
            <Reveal delay={500}>
              <DestinationText>
                <Trans i18nKey="destination" />
              </DestinationText>
            </Reveal>

            <Reveal delay={500} animation={Animation.SlideInLeft}>
              <VisionIconWrapper>
                <Image path="vision@2x.png" />
              </VisionIconWrapper>
            </Reveal>
            <Reveal delay={500}>
              <VisionText>
                <Trans i18nKey="vision" />
              </VisionText>
            </Reveal>

            <Reveal delay={500} animation={Animation.SlideInLeft}>
              <MissionIconWrapper>
                <Image path="mission@2x.png" />
              </MissionIconWrapper>
            </Reveal>
            <Reveal delay={500}>
              <MissionText>
                <Trans i18nKey="mission" />
              </MissionText>
            </Reveal>

            <Reveal delay={500} animation={Animation.SlideInLeft}>
              <ValueIconWrapper>
                <Image path="value@2x.png" />
              </ValueIconWrapper>
            </Reveal>
            <Reveal delay={500}>
              <ValueText>
                <Trans i18nKey="value" />
              </ValueText>
            </Reveal>
          </Wrapper>
        </Container>
      )}

      {company && history && (
        <Container>
          <Wrapper>
            <Reveal delay={500}>
              <HistoryIconWrapper>
                <Image path="history@2x.png" />
              </HistoryIconWrapper>
            </Reveal>
            {history.map((h, i) => (
              <Reveal delay={500} key={i}>
                <HistoryText>
                  <Date>
                    {(i18n.language === 'ja' ? h.date : h.enDate)
                      .split('\n')
                      .map((m, i) => (
                        <span key={i}>
                          {m}
                          <br />
                        </span>
                      ))}
                  </Date>
                  <Event>{i18n.language === 'ja' ? h.event : h.enEvent}</Event>
                </HistoryText>
              </Reveal>
            ))}
          </Wrapper>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
    :first-child {
      padding-top: 35px;
    }
  `}
  ${customMedia.greaterThan('medium')`
    :first-child {
      padding-top: 70px;
    }
  `}

  background-color: #efefef;

  :nth-child(2) {
    background-color: #fff;
  }
`

const Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
    padding: 35px 20px;
  `}
  ${customMedia.between('medium', 'large')`
    margin: 0 auto;
    padding: 70px 0;
    width: 735px;
  `}
  ${customMedia.greaterThan('large')`
    margin: 0 auto;
    padding: 70px 0;
    width: 1000px;
  `}
`

const CompanyIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 25px;
    width: 90px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 65px;
    width: 180px;
  `}
`

const CompanyDetailWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 35px;
  `}
`

const Title = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.1rem;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
  `}

  color: #26d2ad;
  font-weight: bold;
`

const Content = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.1rem;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
  `}

  color: #60656b;
`

const StockHolderContent = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.1rem;
    margin-bottom: 6px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
    margin-bottom: 12px;
  `}

  color: #60656b;
`

const DestinationIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 25px;
    width: 112px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 65px;
    width: 225px;
  `}
`

const DestinationText = styled.h1`
  ${customMedia.lessThan('medium')`
    font-size 2rem;
    margin-bottom: 40px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size 4.8rem;
    margin-bottom: 100px;
  `}

  color: #60656b;
  font-weight bold;
  text-align: center;
`

const VisionIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 12px;
    width: 47px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 65px;
    width: 108px;
  `}
`

const VisionText = styled.h1`
  ${customMedia.lessThan('medium')`
    font-size 2rem;
    margin-bottom: 38px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size 4.3rem;
    margin-bottom: 100px;
  `}

  color: #60656b;
  font-weight bold;
  text-align: center;
`

const MissionIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 12px;
    width: 55px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 65px;
    width: 130px;
  `}
`

const MissionText = styled.h1`
  ${customMedia.lessThan('medium')`
    font-size 2rem;
    margin-bottom: 38px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size 4.3rem;
    margin-bottom: 100px;
  `}

  color: #60656b;
  font-weight bold;
  text-align: center;
`

const ValueIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 12px;
    width: 50px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 65px;
    width: 118px;
  `}
`

const ValueText = styled.h1`
  ${customMedia.lessThan('medium')`
    font-size 2rem;
    margin-bottom: 5px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size 4.3rem;
    margin-bottom: 40px;
  `}

  color: #60656b;
  font-weight bold;
  text-align: center;
`

const EnValueText = styled.h2`
  ${customMedia.lessThan('medium')`
    font-size 1.75rem;
    margin-bottom: 10px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size 3.2rem;
  `}

  color: #60656b;
  font-weight bold;
  text-align: center;
`

const HistoryIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 0 auto 25px;
    width: 72.5px;
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto 65px;
    width: 145px;
  `}
`

const HistoryText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size 1.1rem;
    margin-bottom: 25px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size 2rem;
    margin-bottom: 60px;
  `}
`

const Date = styled.span`
  ${customMedia.lessThan('medium')`
    margin-bottom: 10px;
    :after {
      white-space: pre;
      content: '\n';
    }
  `}
  ${customMedia.greaterThan('medium')`
    margin-right: 25px;
  `}

  color: #26d2ad;
  font-weight bold;
`

const Event = styled.span`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  color: #60656b;
`
